<template>
    <div class="app">
        <el-dialog
                title="物流详情"
                :visible.sync="dialogSendVisible"
                width="30%"
                :before-close="handleSendClose"
        >
            <el-timeline>
                <el-row class="top">
                    <el-col :span="4">
                        <el-avatar :src="info.logo" fit=""></el-avatar>
                    </el-col>
                    <el-col :span="20">
                        <el-row>
                            <el-col :span="12"><b>物流公司：</b>{{info.name}}</el-col>
                            <el-col :span="12"><b>订单编号：</b>{{info.no}}</el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12"><b>送货人：</b>{{info.courierPhone}}</el-col>
                            <el-col :span="12"><b>时长：</b>{{info.takeTime}}</el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-timeline-item
                        v-for="(activity, index) in activities"
                        :key="index"

                        :timestamp="activity.time"
                >
                    {{ activity.content }}
                </el-timeline-item>
            </el-timeline>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        components: {},
        props: {
            row: Object,
        },
        data() {
            return {
                dialogSendVisible: false,
                info: "",
                activities: [],
            };
        },
        methods: {
            handleSendClose() {
                this.dialogSendVisible = false;
                this.$emit("dialog", false);
            },
            showDetail() {
                const data = {no: this.row.trackingNum, type: this.row.logisticsCode};
                this.$post(this.$api.logisticsInfo, data).then((res) => {
                    console.log(res.code);
                    if (res.code === 'OK') {
                        this.$message({
                            type: "success",
                            message: "成功!",
                        });
                        this.info = res;
                        this.activities = this.info.list;
                    } else {
                        this.$message({
                            type: "error",
                            message: res.msg || res.message,
                        });
                    }
                });
            },
        },
        created() {
            this.dialogSendVisible = true;
            this.showDetail();
        },
    };
</script>
<style scoped lang="scss">
    .top{
        padding-bottom: 30px;
    }
</style>
