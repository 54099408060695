<template>
  <div class="app">
    <div class="header-form">
      <div class="admin_main_block_left">
        <el-form>
          <el-form-item label="订单类型">
            <el-select
                v-model="where.orderType"
                placeholder="请选择"
            >
              <el-option
                  label="惠豆商城"
                  value="10"
              ></el-option>
              <el-option
                  label="精品馆"
                  value="20"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="订单状态">
            <el-select v-model="where.orderStatus" placeholder="请选择">
              <el-option label="全部" value=""></el-option>
              <el-option
                  v-for="(val, key, index) in orderStatusList"
                  :label="val"
                  :value="index"
                  :key="key"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="关键字">
            <el-input
                v-model="where.keyWord"
                placeholder="请输入订单编号/会员账号"
                clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="下单时间">
            <el-date-picker
                v-model="date"
                @change="orderDate" @input="immediUpdate"
                type="daterange"
                range-separator="~"
                clearable
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                start-placeholder="开始月份"
                end-placeholder="结束月份"
            >
              <i class="el-icon-date"></i>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" plain icon="el-icon-search" @click="listData()"
            >条件筛选
            </el-button
            >
            <el-button type="primary" plain icon="el-icon-search" @click="reset()"
            >重置
            </el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="admin_main_block_right">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-button
                :disabled="!(orderStatus == 4 && orderStatus >= 0)"
                type="warning"
                @click="batchSend"
                icon="el-icon-finished"
            >批量发货
            </el-button
            >
          </el-col>
          <el-col :span="12">
            <el-button
                type="primary"
                @click="exportData"
                icon="el-icon-tickets"
                :loading="download"
            >导出数据
            </el-button
            >
          </el-col>
        </el-row>
        <div></div>
      </div>
    </div>
    <el-table :data="list" @selection-change="handleSelectionChange">
      <el-table-column type="selection"></el-table-column>
      <el-table-column
          prop="orderNo"
          label="订单编号"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column label="商品名称" width="400px">
        <template slot-scope="scope">
          <dl class="table_dl">
            <dt>
              <el-image
                      style="width: 50px; height: 50px"
                      fit="contain"
                      :src="scope.row.goodsImage"
              >
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i></div
                >
              </el-image>
            </dt>
            <dd class="table_dl_dd_all" :show-overflow-tooltip="true">
              {{ scope.row.goodsName }}
            </dd>
          </dl>
        </template>
      </el-table-column>
      <el-table-column
              prop="purchasePrice"
              label="采购价"
              :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
              prop="purchaseQuantity"
              label="数量"
              :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
          prop="createDate"
          label="下单时间"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
          prop="account"
          label="会员账号"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
          prop="userMobile"
          label="会员手机号"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
          prop="exchangeBeanNum"
          label="消耗惠豆"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
          prop="countExchangeFixNum"
          label="消耗贡献值"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
          prop="shouldPayMoney"
          label="现金应付"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
          prop="freight"
          label="运费（元）"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
          prop="payDate"
          label="支付时间"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
          prop=""
          label="订单来源"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="" label="订单状态" :show-overflow-tooltip="true">
        <template slot-scope="scope">

          <el-tag type="warning" v-if="scope.row.orderStatus == 100">
            待支付
          </el-tag>

          <el-tag type="success" v-if="scope.row.orderStatus == 110">
            支付成功
          </el-tag>

          <el-tag type="danger" v-if="scope.row.orderStatus == 120">
            支付失败
          </el-tag>

          <el-tag type="success" v-if="scope.row.orderStatus == 1">{{
              scope.row.orderStatusName
            }}
          </el-tag>
          <el-tag type="warning" v-else-if="scope.row.orderStatus == 4">{{
              scope.row.stateText
            }}
          </el-tag>
          <el-tag type="danger" v-else-if="scope.row.orderStatus == 5">已发货，待收货
          </el-tag>
          <el-tag type="primary" v-else-if="scope.row.orderStatus == 6">已收货
          </el-tag>
          <el-tag type="info" v-else-if="scope.row.orderStatus == 142">待付运费超时
          </el-tag>
          <el-tag v-else>{{ orderStatusL[scope.row.orderStatus] }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="操作" fixed="right" width="200px">
        <template slot-scope="scope">
          <el-row>
            <el-col :span="8">
              <el-button
                  type="success"
                  icon="el-icon-tickets"
                  plain
                  @click="
                  $router.push({
                    name: 'orderDetail',
                    query: { id: scope.row.id },
                  })
                "
              >查看
              </el-button>
            </el-col>
            <el-col
                :span="8"
                v-if="
                (orderStatus < 4 && orderStatus >= 0) ||
                scope.row.orderStatus == 142
              "
            >
              <el-button
                  type="danger"
                  icon="el-icon-edit small"
                  plain
                  @click="cancelDialog(scope.row)"
              >
                取消
              </el-button>
            </el-col>
            <el-col :span="8" v-if="orderStatus == 4 && orderStatus >= 0">
              <el-button
                  type="primary"
                  icon="el-icon-edit small"
                  plain
                  @click="sendDialog(scope.row)"
              >
                发货
              </el-button>
            </el-col>
            <el-col
                :span="8"
                v-if="
                (orderStatus == 5 && orderStatus >= 0) ||
                scope.row.orderStatus == 5
              "
            >
              <el-button
                  type="info"
                  icon="el-icon-time"
                  plain
                  @click="logisticsDialog(scope.row)"
              >物流
              </el-button>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <div class="admin_table_main_pagination">
      <el-pagination
          @current-change="currentChange"
          @size-change="handleSizeChange"
          background
          :total="totalData"
          :page-size="pageSize"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          layout="sizes, prev, pager, next, total, jumper"
      ></el-pagination>
    </div>
    <cancel :row="row" @dialog="getDialog" v-if="dialogCancelVisible"></cancel>
    <send :row="row" @dialog="getDialog" v-if="dialogSendVisible"></send>
    <logistics
        :row="row"
        @dialog="getDialog"
        v-if="dialogLogisticsVisible"
    ></logistics>
  </div>
</template>
<script>
import cancel from "@/components/admin/mall/order/cancel.vue";
import send from "@/components/admin/mall/order/send.vue";
import logistics from "@/components/admin/mall/order/logistics.vue";
import {exportFile} from "@/plugins/function.js";

export default {
  components: {
    cancel,
    send,
    logistics,
  },
  props: {
    orderStatus: Number,
  },
  data() {
    return {
      list: [],
      date: "",
      download: false,
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 0,
      row: "",
      selectId : "",
      orderStatusL: {
        0: "待付",
        1: "已支付",
        2: "已支付运费",
        3: "取消订单",
        4: "待发货",
        5: "已发货，待收货",
        6: "已收货 , 订单已完成",
        8: "售后中（退货申请待审核）",
        9: "交易关闭（退货审核不通过）",
        10: "交易中（待寄送退货商品）",
        11: "售后中（退货商品待收货）",
        12: "售后中（退货待入库）",
        13: "售后中（退货已入库）",
        14: "交易关闭（完成退款）",
        141: "待付超时",
        142: "待付运费超时",
      },
      orderStatusList: [],
      logisticsInfos: [],
      where: {
        endDate: "",
        keyWord: "",
        limit: 0,
        orderStatus: "",
        page: 0,
        startDate: "",
        userId: 0,
        orderType: "10",
      },
      dialogCancelVisible: false,
      dialogSendVisible: false,
      dialogLogisticsVisible: false,
    };
  },
  // watch: {
  //   dialogSendVisible(newName, oldName) {
  //     console.log(newName, oldName);
  //   },
  // },
  methods: {
    listData() {
      this.where.limit = this.pageSize;
      this.where.shopId = this.shopId;
      this.where.page = this.currentPage;
      this.$post(this.$api.orderlist, this.where).then((res) => {
        if (res.code == 1) {
          this.totalData = res.data.count;
          this.list = res.data.data;
        }
      });
    },
    reset() {
      this.where = {};
      this.date = "";
      this.listData();
    },
    orderStatusData() {
      this.$post(this.$api.orderStates, {}).then((res) => {
        if (res.code == 1) {
          this.orderStatusList = res.data;
        }
      });
    },
    handleSelectionChange(e) {
      let ids = [];
      e.forEach((v) => {
        ids.push(v);
      });
      this.selectId = ids;
    },
    currentChange(e) {
      this.currentPage = e;
      this.listData();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.listData();
    },
    orderDate(obj) {
      if (obj) {
        this.where.startDate = obj[0];
        this.where.endDate = obj[1];
      }
    },
    immediUpdate(e) {
      this.$nextTick(() => {
        console.log(e)
        if(e==null){
          this.where.startDate = '';
          this.where.endDate = '';
        }
      });
    },
    exportData() {
      this.download = true;
      this.$post(this.$api.orderlistExport, this.where, true).then((res) => {
        exportFile(res.data, decodeURI(res.headers.filename));
        this.download = false;
      });
    },
    sendDialog(row) {
      this.row = row;
      this.dialogSendVisible = true;
    },
    cancelDialog(row) {
      this.row = row;
      this.dialogCancelVisible = true;
    },
    logisticsDialog(row) {
      this.row = row;
      this.dialogLogisticsVisible = true;
    },
    getDialog(val) {
      this.dialogSendVisible = val;
      this.dialogCancelVisible = val;
      this.dialogLogisticsVisible = val;
    },
    batchSend() {
      if (this.$isEmpty(this.selectId)) {
        return this.$message.error("请先选择操作对象");
      }
      this.$emit("get-batch", {data: this.selectId, bool: false});
    },
  },
  created() {
    this.where.orderStatus = this.orderStatus == -1 ? "" : this.orderStatus;
    this.listData();
    this.orderStatusData();
  },
};
</script>
<style lang="scss" scoped>
// .admin_main_block_right{
//     /*width: 14%;*/
// }
.admin_main_block_right .el-row {
  width: 100%;
}

.admin_main_block_right div {
  float: left;
}
</style>

